import axios from 'axios'
import {Auth} from './auth'

export const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'http://localhost/api'
})

const defaultLang = process.env.VUE_APP_DEFAULT_LANG || 'en'

/**
 * Get language code
 * @returns {string | string}
 */
const getLang = () => localStorage.getItem('lang') || defaultLang

axiosInstance.interceptors.request.use(
  function (req) {
    req.headers.common['Accept-Language'] = defaultLang // getLang()

    let authHeader = Auth.getAuthorizationHeader(req.url)
    if (authHeader) {
      req.headers.common['Authorization'] = authHeader
    }

    return req
  },
  function (error) {
    return Promise.reject(error)
  }
)

export const AxiosPlugin = {
  install (Vue) {
    Vue.prototype.axios = axiosInstance
    Vue.prototype.$http = axiosInstance
    Vue.axios = axiosInstance
    Vue.$http = axiosInstance
  }
}
