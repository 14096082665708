import Vue from 'vue'

export default {
  load () {
    return Vue.$http.get('v1/projects')
  },
  create (name) {
    return Vue.$http.post('v1/projects/add', {name})
  },
  update (project) {
    return Vue.$http.post(`v1/projects/${project.id}`, {name: project.name})
  },
  remove (id) {
    return Vue.$http.post(`v1/projects/${id}/delete`)
  }
}
