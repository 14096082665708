<template>
  <v-footer
    class="flex-column align-stretch"
    color="var(--v-secondary-darken1)"
    absolute
    app
    dark
  >
    <v-container class="fill-height">
      <div class="footer-logo">
        <v-img src="../assets/algo_logo.svg" width="144" />
        <div class="mt-4">
          <a :href="githubApp.url" class="d-flex align-center">
            <v-img src="../assets/github-white-logo-sm.svg" width="32" class="flex-grow-0" />
            <div class="ml-2">
              {{ githubApp.title }}
            </div>
          </a>
        </div>
      </div>

      <div class="footer-links d-sm-flex justify-sm-start flex-sm-wrap flex-md-column justify-md-start">
        <div
          v-for="(url, i) in urls"
          :key="`footer-url-${i}`"
          class="pb-5"
        >
          <a
            :href="url.href"
            class="d-block body-2 px-sm-3"
            @click="$gtm.trackEvent(url.gtmEvent)"
          >
            {{ url.text }}
          </a>
        </div>
      </div>

      <div class="footer-contacts">
        <h2 class="mb-6">
          {{ $t('Contact us') }}
        </h2>
        <div class="body-2">
          <div v-html="info.contacts.address[0]" />
          <div v-html="info.contacts.address[1]" />
        </div>
        <div class="mt-4">
          <v-icon class="mr-2">
            {{ icons.email }}
          </v-icon>
          <a
            :href="`mailto:${info.contacts.mail}`"
            @click="$gtm.trackEvent({event: 'click-on-contacts'})"
            v-text="info.contacts.mail"
          />
        </div>
        <div class="mt-4">
          <v-icon class="mr-2">
            {{ icons.phone }}
          </v-icon>
          <a
            :href="`tel:${info.contacts.phone.format.link}`"
            @click="$gtm.trackEvent({event: 'click-on-contacts'})"
            v-html="info.contacts.phone.format.text"
          />
        </div>
        <div class="mt-4">
          <a
            v-for="(url, network) in info.contacts.socials"
            :key="`soc-${network}`"
            :href="url"
            target="_blank"
            rel="nofollow"
            class="mr-4 pb-2 link-social"
            @click="$gtm.trackEvent({event: 'click-on-socials'})"
          >
            <v-icon>{{ icons[network] }}</v-icon>
          </a>
        </div>
      </div>

      <div class="footer-partners">
        <h2 class="mb-6">
          {{ $t('Partners') }}
        </h2>
        <div>
          <v-img src="../assets/landing/stripe-logo.svg" width="94" />
        </div>
      </div>

      <div id="subscribe" class="footer-subscribe">
        <FormSubscribe />
      </div>
    </v-container>

    <v-divider />
    <p class="mx-auto py-6 my-0 text-center copyright">
      {{ info.copyright }}
    </p>
  </v-footer>
</template>

<script>
import {mapState} from 'vuex'
import {mdiEmailOutline, mdiFacebook, mdiInstagram, mdiLinkedin, mdiMenu, mdiPhone} from '@mdi/js'
import FormSubscribe from '@/components/FormSubscribe'

export default {
  name: 'AppFooter',
  components: {FormSubscribe},
  data () {
    return {
      urls: [
        {
          text: this.$t('Blog'),
          href: process.env.VUE_APP_SUBDOMAIN_LANDING + '/blog',
          gtmEvent: {event: 'click-on-blog'}
        },
        {
          text: this.$t('Privacy Policy'),
          href: process.env.VUE_APP_SUBDOMAIN_DOCS + '/docs/terms.pdf',
          gtmEvent: {event: 'click-on-privacy'}
        },
        {
          text: this.$t('Terms of Use'),
          href: process.env.VUE_APP_SUBDOMAIN_DOCS + '/docs/privacy.pdf',
          gtmEvent: {event: 'click-on-terms'}
        },
        {
          text: this.$t('White Paper for Developers'),
          href: process.env.VUE_APP_SUBDOMAIN_DOCS + '/docs/wp.pdf',
          gtmEvent: {event: 'click-on-wp-code'}
        },
        {
          text: this.$t('White Paper for Designers'),
          href: process.env.VUE_APP_SUBDOMAIN_DOCS + '/docs/wpd.pdf',
          gtmEvent: {event: 'click-on-wp-design'}
        },
      ],
      contacts: {
        address: '12&nbsp;Mulberry&nbsp;Place, Pinnell&nbsp;Road,<br>' +
          'London, England, SE9&nbsp;6AR',
        email: 'info@algo.id',
        phone: '+442045770253',
        phoneFormatted: '+44 2045 770 253'
      },
      icons: {
        email: mdiEmailOutline,
        phone: mdiPhone,
        instagram: mdiInstagram,
        menu: mdiMenu,
        facebook: mdiFacebook,
        linkedin: mdiLinkedin
      }
    }
  },
  computed: mapState({
    githubApp: state => state.config.app?.github || {},
    info: state => state.config.info,
  })
}
</script>

<style lang="scss">
.v-application footer {
  & > .container {
    --footer-padding-y: 46px;
    padding-top: var(--footer-padding-y);
    padding-bottom: var(--footer-padding-y);
    display: grid;
    align-items: start;
    column-gap: 2rem;
    row-gap: 1rem;
    grid-template-columns: auto 150px;
    grid-template-rows: repeat(4, auto);
    grid-template-areas:
      'links logo'
      'contacts contacts'
      'partners partners'
      'subscribe subscribe';
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      grid-template-columns: auto 150px auto;
      grid-template-rows: repeat(3, auto);
      grid-template-areas:
        'logo links links'
        'contacts contacts partners'
        'subscribe subscribe subscribe';
    }
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      --footer-padding-y: 75px;
      grid-template-columns: repeat(4, minmax(auto, 1fr));
      grid-template-rows: auto auto;
      grid-template-areas:
        'logo links contacts partners'
        'logo links subscribe subscribe';
    }
  }

  .footer-logo {
    grid-area: logo;
  }

  .footer-links {
    grid-area: links;

    a {
      display: block;
    }
  }

  .footer-contacts {
    grid-area: contacts;
  }

  .footer-partners {
    grid-area: partners;
  }

  .footer-subscribe {
    grid-area: subscribe;
  }

  &.theme--dark {
    background-color: var(--v-secondary-darken1)
  }

  &.theme--dark h2 {
    color: var(--v-secondary-lighten1) !important
  }

  &.theme--dark a {
    color: #fff !important;

    &.link-social:hover {
      text-decoration: none;
    }
  }

  &.theme--dark .copyright {
    color: var(--v-secondary-lighten1) !important
  }
}
</style>
