<template>
  <v-app id="app">
    <error-global />
    <error-modal />

    <v-overlay
      :value="!error && (!ready || $route.name === 'auth-complete')"
      class="align-start pt-16"
      opacity="0"
      absolute
    >
      <div class="pt-16">
        <v-progress-circular class="mt-16" color="primary" indeterminate />
      </div>
    </v-overlay>

    <app-nav-user v-if="auth" />
    <app-nav-anon v-else />

    <v-main class="mb-10 mb-sm-0">
      <div v-if="!ready" style="min-height: 200px" />
      <template v-if="!error && ready">
        <app-notify />
        <router-view />
      </template>
    </v-main>

    <app-footer v-if="ready" />
  </v-app>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import AppNavUser from '@/components/AppNavUser'
import AppNavAnon from '@/components/AppNavAnon'
import AppNotify from '@/components/AppNotify'
import ErrorGlobal from '@/components/ErrorGlobal'
import ErrorModal from '@/components/ErrorModal'
import AppFooter from '@/components/AppFooter'

export default {
  name: 'App',
  components: {
    AppFooter,
    AppNavUser,
    AppNavAnon,
    AppNotify,
    ErrorGlobal,
    ErrorModal
  },
  computed: {
    ...mapState({
      error: state => state.error.global.status,
      ready: state => state.ready,
    }),
    auth () {
      return this.$auth.watch.auth
    }
  },
  async created () {
    try {
      await this.init()
    } catch (e) {
      this.setError({mode: 'global', error: e})
    }
  },
  methods: {
    ...mapMutations({
      setError: 'error/set'
    }),
    ...mapActions(['init'])
  }
}
</script>
