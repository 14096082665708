import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import {light} from './themes'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light
    }
  }
})
