const DEFAULT_COLOR = 'info'
const DEFAULT_TIMEOUT = 5000

export default {
  namespaced: true,
  state: {
    color: DEFAULT_COLOR,
    text: '',
    timeout: DEFAULT_TIMEOUT,
    visible: false
  },
  mutations: {
    show: (state, {text, color = DEFAULT_COLOR, timeout = DEFAULT_TIMEOUT}) => {
      state.text = text
      state.color = color
      state.timeout = timeout
      state.visible = true
    },
    hide: state => {
      state.visible = false
    }
  }
}
