export const light = {
  primary: {
    base: '#ff6b00',
    lighten1: '#ff9243'
  },
  secondary: {
    base: '#222287',
    darken1: '#131356',
    lighten1: '#5a5a89',
    lighten2: '#9a9db7'
  },
  background: {
    base: '#f5f7fd'
  },
  surface: {
    base: '#ffffff',
    darken1: '#f5f7fd'
  },
  avatar: {
    base: '#cfdaf9'
  },
  text: {
    base: '#252525',
    lighten1: '#777777'
  },
  outline: {
    base: '#e0e7ff'
  },
  error: '#f04304'
}
