import {RepositoryFactory} from '@/repositories/_repository-factory'

const teammatesRepository = RepositoryFactory.get('businessTeammates')

export default {
  namespaced: true,
  actions: {
    async tmAdd ({commit}, email) {
      let {data: {data}} = await teammatesRepository.add(email)
      commit('addTeammate', data, {root: true})
    },
    async tmRemove ({commit}, accountId) {
      await teammatesRepository.remove(accountId)
      commit('removeTeammate', accountId, {root: true})
    },
  }
}
