import Vue from 'vue'
import Vuex from 'vuex'
import {i18n} from '@/plugins/i18n'

import deposits from './deposits'
import projects from './projects'
import error from './error'
import notify from './notify'
import subscriptions from './subscriptions'

import depositsBusiness from './business/deposits'
import projectsBusiness from './business/projects'
import teammatesBusiness from './business/teammates'

import {RepositoryFactory} from '@/repositories/_repository-factory'
import {COUNTRIES} from './const-countries-code'

const authRepository = RepositoryFactory.get('auth')
const configRepository = RepositoryFactory.get('config')
// Can be changed in action loadProfile
let userRepository = RepositoryFactory.get('user')

const defaultLang = process.env.VUE_APP_DEFAULT_LANG || 'en'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    deposits,
    projects,
    error,
    notify,
    subscriptions,
    depositsBusiness,
    projectsBusiness,
    teammatesBusiness,
  },
  state: {
    locale: 'en',
    ready: false,
    config: {},
    profile: {},
    loaders: {
      auth: {title: 'Auth', status: false},
      config: {title: 'Config', status: false},
      profile: {title: 'Profile', status: false},
      deposits: {title: 'Deposits', status: false}
    }
  },
  mutations: {
    setNotReady: state => {
      state.ready = false
    },
    setReady: state => {
      state.ready = true
    },
    setConfig: (state, config) => {
      state.config = {...config}
    },
    setProfile: (state, profile) => {
      state.profile = {...state.profile, ...profile}
      if (!state.profile.name) {
        state.profile.name =
          `${state.profile.first_name} ${state.profile.last_name}`
      }
    },
    setLoaded: (state, loader) => {
      state.loaders[loader].status = true
    },
    setUnloaded: (state, loader) => {
      state.loaders[loader].status = false
    },
    setLocale: (state, locale) => {
      state.locale = locale
    },

    // Teammates mutations
    addTeammate: (state, teammate) => {
      state.profile.organization.teammates.push(teammate)
    },
    removeTeammate: (state, accountId) => {
      let i = state.profile.organization.teammates
        .findIndex(tm => tm.account_id === accountId)
      if (i !== -1) {
        state.profile.organization.teammates.splice(i, 1)
      }
    },
  },
  getters: {
    getCurrencies: state => {
      if (!state.config.currencies) return []
      return Object.keys(state.config.currencies)
        .map(code => ({
          text: state.config.currencies[code].name,
          value: code
        }))
    },
    getCountries: () => {
      return COUNTRIES.map(({alpha2, country}) => ({
        text: country,
        value: alpha2
      }))
    },
    getLanguagesOptions: state => {
      let langs = state.config.deposit?.types?.implementation?.languages
      if (!langs) return []
      return Object.keys(langs).map(lng => ({text: langs[lng], value: lng}))
    },
    getTerritoriesOptions: state => {
      let territories = state.config?.deposit?.actors?.territories
      if (!territories) return []
      return Object.keys(territories)
        .map(t => ({text: territories[t], value: t}))
    },
    getDepositTypes: state => {
      if (!state.config.deposit.types) return []
      return Object.keys(state.config.deposit.types)
        .map(id => ({
          text: state.config.deposit.types[id].title,
          value: id
        }))
    },
    getBusinessTypes: state => {
      if (!state.config.deposit.actor.business_type) return []
      return Object.keys(state.config.deposit.actor.business_type)
        .map(id => ({
          text: state.config.deposit.actor.business_type[id].title,
          value: id
        }))
    },
    getAvatarStyle: state => {
      return state.profile.avatar
        ? {backgroundImage: `url("${state.profile.avatar}")`}
        : {}
    },
    getActivePlan: state => {
      return state.profile.subscribed?.plan || {}
    }
  },
  actions: {
    async registerUser ({commit}, user) {
      return authRepository.register(user)
    },
    async resendVerificationLink () {
      return authRepository.resendVerificationLink()
    },
    async init ({commit, dispatch}) {
      if (Vue.$auth.check()) {
        let refreshTask = Vue.$auth.refresh()
        let configTask = dispatch('loadConfig')
        await refreshTask
        await configTask
      } else {
        await dispatch('loadConfig')
      }
      let lng = localStorage.getItem('lang') || defaultLang
      await dispatch('changeLanguage', lng)

      if (!Vue.$auth.watch.authenticating) commit('setReady')
      Vue.$auth.watch.$emit('ready')
    },
    async loadConfig ({commit}) {
      let {data: {data, result}} = await configRepository.get()

      if (result) commit('setConfig', data)
      commit('setLoaded', 'config')
    },
    async loadProfile ({commit}) {
      let {data: {data, result}} = await userRepository.profile()
      if (result) commit('setProfile', data)
      commit('setLoaded', 'profile')

      if (data.accountType === 'business') {
        userRepository = RepositoryFactory.get('businessUser')
      }
    },
    async updateProfile ({commit}, data) {
      let response = await userRepository.update(data)
      if (response.data.result) commit('setProfile', response.data.data)
    },
    clearProfile ({commit}) {
      commit('setProfile', {})
      commit('setUnloaded', 'profile')
    },

    async sendVerificationCode ({state}, phone) {
      await authRepository.sendVerificationCode(phone)
    },
    async resendVerificationCode () {
      await authRepository.resendVerificationCode()
    },
    async verifyCode ({dispatch}, code) {
      let {data} = await authRepository.verifyCode(code)
      return data
    },

    async changeLanguage ({commit}, lng) {
      await i18n.i18next.changeLanguage(lng)
      localStorage.setItem('lang', lng)
      commit('setLocale', lng)
    }
  }
})
