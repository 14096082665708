<template>
  <div />
</template>

<script>
import {mapMutations} from 'vuex'

export default {
  name: 'AuthComplete',
  async created () {
    try {
      let redirectUrl = await this.$auth.authenticate(this.$route.query)
      await this.$router.replace(redirectUrl)
    } catch (e) {
      await this.$router.replace({name: 'register'})
      await this.setError({
        mode: 'modal',
        error: e,
        title: this.$t('Authentication failed')
      })
    }
  },
  methods: {
    ...mapMutations({
      setError: 'error/set'
    })
  }
}
</script>
