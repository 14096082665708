import {RepositoryFactory} from '@/repositories/_repository-factory'

const projectsRepository = RepositoryFactory.get('projects')

export default {
  namespaced: true,
  state: {
    projects: [],
    projectsLoaded: false,
  },
  getters: {
    getProjectById: state => id => {
      return state.projects.find(p => p.id === id * 1)
    },
    getNameById: (state, getters) => projectId => {
      return getters.getProjectById(projectId)?.name
    },
  },
  mutations: {
    setLoaded: (state, loaded) => {
      state.projectsLoaded = loaded
    },
    setProjects: (state, projects) => {
      state.projects = projects
    },
    create: (state, project) => {
      state.projects.push(project)
    },
    update: (state, project) => {
      let i = state.projects.findIndex(p => p.id === project.id)
      if (i !== -1) {
        for (let [key, value] of Object.entries(project)) {
          state.projects[i][key] = value
        }
      }
    },
    remove: (state, id) => {
      let i = state.projects.findIndex(p => p.id === id)
      if (i !== -1) state.projects.splice(i, 1)
    },
  },
  actions: {
    async prLoad ({commit}) {
      let {data: {data}} = await projectsRepository.load()
      commit('setProjects', data)
      commit('setLoaded', true)
    },
    async prCreate ({commit}, name) {
      let {data: {data}} = await projectsRepository.create(name)
      commit('create', data)
      return data.id
    },
    async prUpdate ({commit}, project) {
      let {data: {data}} = await projectsRepository.update(project)
      commit('update', data)
    },
    async prRemove ({commit}, id) {
      await projectsRepository.remove(id)
      commit('remove', id)
    },
  }
}
