<template>
  <v-avatar
    :size="size"
    class="avatar overflow-hidden align-self-center"
  >
    <v-img v-if="image" :src="image" />
    <v-icon v-else color="surface" :size="iconSize">
      {{ icons.avatar }}
    </v-icon>
  </v-avatar>
</template>

<script>
import {mdiAccount} from '@mdi/js'

export default {
  name: 'AppAvatar',
  props: {
    image: {type: String, default: ''},
    size: {type: [Number, String], default: 32}
  },
  data () {
    return {
      icons: {
        avatar: mdiAccount
      }
    }
  },
  computed: {
    iconSize () {
      return Math.floor(this.size * 0.64)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
