import Vue from 'vue'

export default {
  plans () {
    return Vue.$http.get('v1/plans/stripe', {params: {active: true}})
  },
  setupIntent () {
    return Vue.$http.get('v1/payments/stripe/setup-intent')
  },
  paymentMethods () {
    return Vue.$http.get('v1/payments/stripe/methods')
  },
  // eslint-disable-next-line camelcase
  removeMethod (payment_method_id) {
    return Vue.$http.post('v1/payments/stripe/method/remove', {payment_method_id})
  },
  // eslint-disable-next-line camelcase
  createSubscription (plan_id, payment_method_id) {
    return Vue.$http.post('v1/subscriptions/stripe', {plan_id, payment_method_id})
  },
  // eslint-disable-next-line camelcase
  cancelSubscription (subscription_id) {
    return Vue.$http.post('v1/subscriptions/stripe/cancel', {subscription_id})
  },
  // eslint-disable-next-line camelcase
  resumeSubscription (subscription_id) {
    return Vue.$http.post('v1/subscriptions/stripe/resume', {subscription_id})
  }
}
