import Vue from 'vue'

export default {
  profile () {
    return Vue.$http.get('v1/users/profile')
  },
  update (data) {
    return Vue.$http.post('v1/users', data)
  },
}
