export const sections = [
  {
    id: 'active',
    title: 'Active deposits',
    statuses: ['in_progress', 'registered'],
    backFromRoutes: ['deposit-details']
  },
  {
    id: 'drafts',
    title: 'Drafts',
    statuses: ['draft', 'locked'],
    backFromRoutes: ['new-deposit', 'deposit-edit']
  }
]
