<template>
  <v-app-bar
    absolute
    app
    dark
    flat
    prominent
  >
    <v-container class="py-0 fill-height">
      <v-row align="stretch" class="fill-height" no-gutters>
        <!-- logo -->
        <v-col cols="auto" class="d-flex">
          <v-toolbar-title class="mr-4 align-self-center d-flex align-center">
            <a :href="homepageUrl">
              <v-img src="../assets/algo_logo.svg" width="120" />
            </a>
            <a :href="githubApp.url" :title="githubApp.title" class="ml-4">
              <v-img src="../assets/github-white-logo-sm.svg" width="32" />
            </a>
          </v-toolbar-title>
        </v-col>

        <v-spacer />

        <v-col cols="auto" class="d-flex align-center">
          <v-btn
            v-if="ready && $route.name !== 'auth-complete'"
            :href="loginUrl"
            :ripple="false"
            data-cy="login-button"
            dark
            text
          >
            {{ $t('Login') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'AppNavAnon',
  data () {
    return {
      homepageUrl: process.env.VUE_APP_SUBDOMAIN_LANDING,
      loginUrl: process.env.VUE_APP_IPCHAIN_LOGIN_URL
    }
  },
  computed: {
    ...mapState({
      ready: state => state.ready,
      githubApp: state => state.config.app?.github || {}
    })
  }
}
</script>

<style lang="scss" scoped>
</style>
