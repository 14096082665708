import Vue from 'vue'

export default {
  profile () {
    return Vue.$http.get('v1/user/profile')
  },
  update (data) {
    return Vue.$http.post('v2/business/users', data)
  },
}
