<template>
  <v-form ref="form" class="d-flex flex-wrap mt-3">
    <v-text-field
      v-model="subscribeEmail"
      :disabled="subscribing"
      :error-messages="feedback('email')"
      :loading="subscribing"
      :placeholder="$t('Your email')"
      :rules="rules.email"
      :success-messages="successMessage"
      autocomplete="off"
      class="mr-3 subscribe-input"
      flat
      light
      rounded
      solo
      validate-on-blur
      @focus="successMessage = ''"
      @keypress.native.enter="subscribe"
    />
    <v-btn
      :disabled="subscribing"
      :ripple="false"
      color="primary"
      rounded
      @click="subscribe"
    >
      {{ $t('Subscribe to blog') }}
    </v-btn>
  </v-form>
</template>

<script>
export default {
  name: 'FormSubscribe',
  data () {
    return {
      subscribeEmail: '',
      subscribing: false,
      serverFeedback: [],
      successMessage: '',
      rules: {
        email: [
          v => /[\w.-]+@[\w.-]+\.[\w.-]+/.test(v) || this.$t('Incorrect email address'),
          v => !!v || this.$t('Email is required'),
        ],
      },
    }
  },
  methods: {
    feedback (field) {
      if (Array.isArray(this.serverFeedback[field])) {
        return this.serverFeedback[field].join(' ')
      }
      return this.serverFeedback[field] || ''
    },
    async subscribe (e) {
      e.preventDefault()
      this.successMessage = ''
      this.serverFeedback = []

      if (this.$refs.form.validate()) {
        this.subscribing = true
        try {
          let response = await this.$http.post('/v1/subscribe-email/add', {
            email: this.subscribeEmail
          })
          if (response.data.result) {
            this.successMessage = response.data.message
            this.$refs.form.reset()
          } else {
            this.serverFeedback['email'] = response.data.message
          }
        } catch (e) {
          if (e.response.status === 422) {
            this.serverFeedback = e.response.data.message
          } else {
            this.serverFeedback['email'] = e.message
          }
        } finally {
          setTimeout(() => {
            this.subscribing = false
          }, 1000)
        }
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
.subscribe-input.v-input {
  min-width: 14rem;
  max-width: 16rem;
}
</style>
