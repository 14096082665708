import {omitBy} from 'lodash'

/**
 * Returns new object omitting fields with null values,
 * empty strings and arrays, because when adding or updating
 * deposit those fields should not be sent to sever
 * TODO: remove double in store/deposits
 * @param {Object} data
 */
export const omitEmpty = data => omitBy(data, (value, key) => {
  if (key === 'actors' && Array.isArray(value) && !value.length) {
    return value.filter(v => v !== null)
  }
  return value === null || value === ''
})

/**
 * When uploading files
 * @param value
 * @param field
 * @param fd
 * @returns {FormData}
 */
export const makeFormData = (value, field = null, fd = new FormData()) => {
  if (value === null || value === '') {
    // omit empty fields
  } else if (value instanceof File) {
    fd.append(field, value)
  } else if (typeof value === 'object') {
    for (let [key, val] of Object.entries(value)) {
      makeFormData(val, field ? `${field}[${key}]` : key, fd)
    }
  } else {
    fd.append(field, value.toString())
  }
  return fd
}

/**
 * Converting '0.5' → '1/2', '0.02' → '1/50', etc.
 * @param {string} decimal
 * @return {string}
 */
export const convertDecimalToFraction = decimal => {
  if (isNaN(decimal) || !/^\d\.\d+$/.test(decimal)) return ''

  const highestCommonFactor = (a, b) => {
    if (b === 0) return a
    return highestCommonFactor(b, a % b)
  }

  let [leftDecimalPart, rightDecimalPart] = decimal.toString().split('.')

  let numerator = leftDecimalPart + rightDecimalPart
  let denominator = Math.pow(10, rightDecimalPart.length)
  let factor = highestCommonFactor(numerator, denominator)
  denominator /= factor
  numerator /= factor
  return `${numerator}/${denominator}`
}
