import Vue from 'vue'

export default {
  load () {
    return Vue.$http.get('v2/business/projects')
  },
  create (project) {
    return Vue.$http.post('v2/business/projects/add', project)
  },
  update (project) {
    return Vue.$http.post(`v2/business/projects/${project.id}`, project)
  },
  remove (id) {
    return Vue.$http.post(`v2/business/projects/${id}/delete`)
  }
}
