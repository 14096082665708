import {RepositoryFactory} from '@/repositories/_repository-factory'

const subscriptionRepository = RepositoryFactory.get('subscription')

export default {
  namespaced: true,
  state: {
    plans: [],
    setupIntent: null,
    paymentMethods: []
  },
  getters: {
    getDefaultPaymentMethodId: state => {
      return state.paymentMethods.find(pm => pm.default)?.id
    }
  },
  mutations: {
    setPlans: (state, plans) => {
      state.plans = plans
    },
    setSetupIntent: (state, intent) => {
      state.setupIntent = {...intent}
    },
    setPaymentMethods: (state, methods) => {
      state.paymentMethods = methods
    },
    removePaymentMethod: (state, methodId) => {
      let i = state.paymentMethods.findIndex(pm => pm.id === methodId)
      if (i !== -1) state.paymentMethods.splice(i, 1)
    }
  },
  actions: {
    async ssLoadPlans ({commit}) {
      let {data: {data}} = await subscriptionRepository.plans()
      commit('setPlans', data)
    },
    async ssSetupIntent ({commit}) {
      let {data: {data}} = await subscriptionRepository.setupIntent()
      commit('setSetupIntent', data)
    },
    async ssLoadPaymentMethods ({commit}) {
      let {data: {data}} = await subscriptionRepository.paymentMethods()
      commit('setPaymentMethods', data)
    },
    async ssRemovePaymentMethod ({commit}, methodId) {
      let result = await subscriptionRepository.removeMethod(methodId)
      commit('removePaymentMethod', methodId)
      return result
    },
    async ssCreateSubscription ({commit}, {planId, methodId}) {
      return subscriptionRepository.createSubscription(planId, methodId)
    },
    async ssCancelSubscription ({commit}, subscriptionId) {
      return subscriptionRepository.cancelSubscription(subscriptionId)
    },
    async ssResumeSubscription ({commit}, subscriptionId) {

    }
  }
}
