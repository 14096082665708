export const COUNTRIES = [
  {alpha2: 'AF', country: 'Afghanistan'},
  {alpha2: 'AL', country: 'Albania'},
  {alpha2: 'DZ', country: 'Algeria'},
  {alpha2: 'AS', country: 'American Samoa'},
  {alpha2: 'AD', country: 'Andorra'},
  {alpha2: 'AO', country: 'Angola'},
  {alpha2: 'AI', country: 'Anguilla'},
  {alpha2: 'AQ', country: 'Antarctica'},
  {alpha2: 'AG', country: 'Antigua and Barbuda'},
  {alpha2: 'AR', country: 'Argentina'},
  {alpha2: 'AM', country: 'Armenia'},
  {alpha2: 'AW', country: 'Aruba'},
  {alpha2: 'AU', country: 'Australia'},
  {alpha2: 'AT', country: 'Austria'},
  {alpha2: 'AZ', country: 'Azerbaijan'},
  {alpha2: 'BS', country: 'Bahamas'},
  {alpha2: 'BH', country: 'Bahrain'},
  {alpha2: 'BD', country: 'Bangladesh'},
  {alpha2: 'BB', country: 'Barbados'},
  {alpha2: 'BY', country: 'Belarus'},
  {alpha2: 'BE', country: 'Belgium'},
  {alpha2: 'BZ', country: 'Belize'},
  {alpha2: 'BJ', country: 'Benin'},
  {alpha2: 'BM', country: 'Bermuda'},
  {alpha2: 'BT', country: 'Bhutan'},
  {alpha2: 'BO', country: 'Bolivia'},
  {alpha2: 'BQ', country: 'Bonaire, Sint Eustatius and Saba'},
  {alpha2: 'BA', country: 'Bosnia and Herzegovina'},
  {alpha2: 'BW', country: 'Botswana'},
  {alpha2: 'BV', country: 'Bouvet Island'},
  {alpha2: 'BR', country: 'Brazil'},
  {alpha2: 'IO', country: 'British Indian Ocean Territory'},
  {alpha2: 'BN', country: 'Brunei Darussalam'},
  {alpha2: 'BG', country: 'Bulgaria'},
  {alpha2: 'BF', country: 'Burkina Faso'},
  {alpha2: 'BI', country: 'Burundi'},
  {alpha2: 'CV', country: 'Cabo Verde'},
  {alpha2: 'KH', country: 'Cambodia'},
  {alpha2: 'CM', country: 'Cameroon'},
  {alpha2: 'CA', country: 'Canada'},
  {alpha2: 'KY', country: 'Cayman Islands'},
  {alpha2: 'CF', country: 'Central African Republic'},
  {alpha2: 'TD', country: 'Chad'},
  {alpha2: 'CL', country: 'Chile'},
  {alpha2: 'CN', country: 'China'},
  {alpha2: 'CX', country: 'Christmas Island'},
  {alpha2: 'CC', country: 'Cocos (Keeling) Islands'},
  {alpha2: 'CO', country: 'Colombia'},
  {alpha2: 'KM', country: 'Comoros'},
  {alpha2: 'CD', country: 'Congo (the Democratic Republic of the)'},
  {alpha2: 'CG', country: 'Congo'},
  {alpha2: 'CK', country: 'Cook Islands'},
  {alpha2: 'CR', country: 'Costa Rica'},
  {alpha2: 'HR', country: 'Croatia'},
  {alpha2: 'CU', country: 'Cuba'},
  {alpha2: 'CW', country: 'Curaçao'},
  {alpha2: 'CY', country: 'Cyprus'},
  {alpha2: 'CZ', country: 'Czechia'},
  {alpha2: 'CI', country: "Côte d'Ivoire"},
  {alpha2: 'DK', country: 'Denmark'},
  {alpha2: 'DJ', country: 'Djibouti'},
  {alpha2: 'DM', country: 'Dominica'},
  {alpha2: 'DO', country: 'Dominican Republic'},
  {alpha2: 'EC', country: 'Ecuador'},
  {alpha2: 'EG', country: 'Egypt'},
  {alpha2: 'SV', country: 'El Salvador'},
  {alpha2: 'GQ', country: 'Equatorial Guinea'},
  {alpha2: 'ER', country: 'Eritrea'},
  {alpha2: 'EE', country: 'Estonia'},
  {alpha2: 'SZ', country: 'Eswatini'},
  {alpha2: 'ET', country: 'Ethiopia'},
  {alpha2: 'FK', country: 'Falkland Islands [Malvinas]'},
  {alpha2: 'FO', country: 'Faroe Islands'},
  {alpha2: 'FJ', country: 'Fiji'},
  {alpha2: 'FI', country: 'Finland'},
  {alpha2: 'FR', country: 'France'},
  {alpha2: 'GF', country: 'French Guiana'},
  {alpha2: 'PF', country: 'French Polynesia'},
  {alpha2: 'TF', country: 'French Southern Territories'},
  {alpha2: 'GA', country: 'Gabon'},
  {alpha2: 'GM', country: 'Gambia'},
  {alpha2: 'GE', country: 'Georgia'},
  {alpha2: 'DE', country: 'Germany'},
  {alpha2: 'GH', country: 'Ghana'},
  {alpha2: 'GI', country: 'Gibraltar'},
  {alpha2: 'GR', country: 'Greece'},
  {alpha2: 'GL', country: 'Greenland'},
  {alpha2: 'GD', country: 'Grenada'},
  {alpha2: 'GP', country: 'Guadeloupe'},
  {alpha2: 'GU', country: 'Guam'},
  {alpha2: 'GT', country: 'Guatemala'},
  {alpha2: 'GG', country: 'Guernsey'},
  {alpha2: 'GN', country: 'Guinea'},
  {alpha2: 'GW', country: 'Guinea-Bissau'},
  {alpha2: 'GY', country: 'Guyana'},
  {alpha2: 'HT', country: 'Haiti'},
  {alpha2: 'HM', country: 'Heard Island and McDonald Islands'},
  {alpha2: 'VA', country: 'Holy See'},
  {alpha2: 'HN', country: 'Honduras'},
  {alpha2: 'HK', country: 'Hong Kong'},
  {alpha2: 'HU', country: 'Hungary'},
  {alpha2: 'IS', country: 'Iceland'},
  {alpha2: 'IN', country: 'India'},
  {alpha2: 'ID', country: 'Indonesia'},
  {alpha2: 'IR', country: 'Iran'},
  {alpha2: 'IQ', country: 'Iraq'},
  {alpha2: 'IE', country: 'Ireland'},
  {alpha2: 'IM', country: 'Isle of Man'},
  {alpha2: 'IL', country: 'Israel'},
  {alpha2: 'IT', country: 'Italy'},
  {alpha2: 'JM', country: 'Jamaica'},
  {alpha2: 'JP', country: 'Japan'},
  {alpha2: 'JE', country: 'Jersey'},
  {alpha2: 'JO', country: 'Jordan'},
  {alpha2: 'KZ', country: 'Kazakhstan'},
  {alpha2: 'KE', country: 'Kenya'},
  {alpha2: 'KI', country: 'Kiribati'},
  {alpha2: 'KP', country: 'Korea (North)'},
  {alpha2: 'KR', country: 'Korea (South)'},
  {alpha2: 'KW', country: 'Kuwait'},
  {alpha2: 'KG', country: 'Kyrgyzstan'},
  {alpha2: 'LA', country: "Lao People's Democratic Republic"},
  {alpha2: 'LV', country: 'Latvia'},
  {alpha2: 'LB', country: 'Lebanon'},
  {alpha2: 'LS', country: 'Lesotho'},
  {alpha2: 'LR', country: 'Liberia'},
  {alpha2: 'LY', country: 'Libya'},
  {alpha2: 'LI', country: 'Liechtenstein'},
  {alpha2: 'LT', country: 'Lithuania'},
  {alpha2: 'LU', country: 'Luxembourg'},
  {alpha2: 'MO', country: 'Macao'},
  {alpha2: 'MG', country: 'Madagascar'},
  {alpha2: 'MW', country: 'Malawi'},
  {alpha2: 'MY', country: 'Malaysia'},
  {alpha2: 'MV', country: 'Maldives'},
  {alpha2: 'ML', country: 'Mali'},
  {alpha2: 'MT', country: 'Malta'},
  {alpha2: 'MH', country: 'Marshall Islands'},
  {alpha2: 'MQ', country: 'Martinique'},
  {alpha2: 'MR', country: 'Mauritania'},
  {alpha2: 'MU', country: 'Mauritius'},
  {alpha2: 'YT', country: 'Mayotte'},
  {alpha2: 'MX', country: 'Mexico'},
  {alpha2: 'FM', country: 'Micronesia'},
  {alpha2: 'MD', country: 'Moldova'},
  {alpha2: 'MC', country: 'Monaco'},
  {alpha2: 'MN', country: 'Mongolia'},
  {alpha2: 'ME', country: 'Montenegro'},
  {alpha2: 'MS', country: 'Montserrat'},
  {alpha2: 'MA', country: 'Morocco'},
  {alpha2: 'MZ', country: 'Mozambique'},
  {alpha2: 'MM', country: 'Myanmar'},
  {alpha2: 'NA', country: 'Namibia'},
  {alpha2: 'NR', country: 'Nauru'},
  {alpha2: 'NP', country: 'Nepal'},
  {alpha2: 'NL', country: 'Netherlands'},
  {alpha2: 'NC', country: 'New Caledonia'},
  {alpha2: 'NZ', country: 'New Zealand'},
  {alpha2: 'NI', country: 'Nicaragua'},
  {alpha2: 'NE', country: 'Niger'},
  {alpha2: 'NG', country: 'Nigeria'},
  {alpha2: 'NU', country: 'Niue'},
  {alpha2: 'NF', country: 'Norfolk Island'},
  {alpha2: 'MP', country: 'Northern Mariana Islands'},
  {alpha2: 'NO', country: 'Norway'},
  {alpha2: 'OM', country: 'Oman'},
  {alpha2: 'PK', country: 'Pakistan'},
  {alpha2: 'PW', country: 'Palau'},
  {alpha2: 'PS', country: 'Palestine'},
  {alpha2: 'PA', country: 'Panama'},
  {alpha2: 'PG', country: 'Papua New Guinea'},
  {alpha2: 'PY', country: 'Paraguay'},
  {alpha2: 'PE', country: 'Peru'},
  {alpha2: 'PH', country: 'Philippines'},
  {alpha2: 'PN', country: 'Pitcairn'},
  {alpha2: 'PL', country: 'Poland'},
  {alpha2: 'PT', country: 'Portugal'},
  {alpha2: 'PR', country: 'Puerto Rico'},
  {alpha2: 'QA', country: 'Qatar'},
  {alpha2: 'MK', country: 'Republic of North Macedonia'},
  {alpha2: 'RO', country: 'Romania'},
  {alpha2: 'RU', country: 'Russian Federation'},
  {alpha2: 'RW', country: 'Rwanda'},
  {alpha2: 'RE', country: 'Réunion'},
  {alpha2: 'BL', country: 'Saint Barthélemy'},
  {alpha2: 'SH', country: 'Saint Helena, Ascension and Tristan da Cunha'},
  {alpha2: 'KN', country: 'Saint Kitts and Nevis'},
  {alpha2: 'LC', country: 'Saint Lucia'},
  {alpha2: 'MF', country: 'Saint Martin (French part)'},
  {alpha2: 'PM', country: 'Saint Pierre and Miquelon'},
  {alpha2: 'VC', country: 'Saint Vincent and the Grenadines'},
  {alpha2: 'WS', country: 'Samoa'},
  {alpha2: 'SM', country: 'San Marino'},
  {alpha2: 'ST', country: 'Sao Tome and Principe'},
  {alpha2: 'SA', country: 'Saudi Arabia'},
  {alpha2: 'SN', country: 'Senegal'},
  {alpha2: 'RS', country: 'Serbia'},
  {alpha2: 'SC', country: 'Seychelles'},
  {alpha2: 'SL', country: 'Sierra Leone'},
  {alpha2: 'SG', country: 'Singapore'},
  {alpha2: 'SX', country: 'Sint Maarten (Dutch part)'},
  {alpha2: 'SK', country: 'Slovakia'},
  {alpha2: 'SI', country: 'Slovenia'},
  {alpha2: 'SB', country: 'Solomon Islands'},
  {alpha2: 'SO', country: 'Somalia'},
  {alpha2: 'ZA', country: 'South Africa'},
  {alpha2: 'GS', country: 'South Georgia and the South Sandwich Islands'},
  {alpha2: 'SS', country: 'South Sudan'},
  {alpha2: 'ES', country: 'Spain'},
  {alpha2: 'LK', country: 'Sri Lanka'},
  {alpha2: 'SD', country: 'Sudan'},
  {alpha2: 'SR', country: 'Suriname'},
  {alpha2: 'SJ', country: 'Svalbard and Jan Mayen'},
  {alpha2: 'SE', country: 'Sweden'},
  {alpha2: 'CH', country: 'Switzerland'},
  {alpha2: 'SY', country: 'Syrian Arab Republic'},
  {alpha2: 'TW', country: 'Taiwan'},
  {alpha2: 'TJ', country: 'Tajikistan'},
  {alpha2: 'TZ', country: 'Tanzania'},
  {alpha2: 'TH', country: 'Thailand'},
  {alpha2: 'TL', country: 'Timor-Leste'},
  {alpha2: 'TG', country: 'Togo'},
  {alpha2: 'TK', country: 'Tokelau'},
  {alpha2: 'TO', country: 'Tonga'},
  {alpha2: 'TT', country: 'Trinidad and Tobago'},
  {alpha2: 'TN', country: 'Tunisia'},
  {alpha2: 'TR', country: 'Turkey'},
  {alpha2: 'TM', country: 'Turkmenistan'},
  {alpha2: 'TC', country: 'Turks and Caicos Islands'},
  {alpha2: 'TV', country: 'Tuvalu'},
  {alpha2: 'UG', country: 'Uganda'},
  {alpha2: 'UA', country: 'Ukraine'},
  {alpha2: 'AE', country: 'United Arab Emirates'},
  {alpha2: 'GB', country: 'United Kingdom'},
  {alpha2: 'UM', country: 'United States Minor Outlying Islands'},
  {alpha2: 'US', country: 'United States of America'},
  {alpha2: 'UY', country: 'Uruguay'},
  {alpha2: 'UZ', country: 'Uzbekistan'},
  {alpha2: 'VU', country: 'Vanuatu'},
  {alpha2: 'VE', country: 'Venezuela'},
  {alpha2: 'VN', country: 'Viet Nam'},
  {alpha2: 'VG', country: 'Virgin Islands (British)'},
  {alpha2: 'VI', country: 'Virgin Islands (U.S.)'},
  {alpha2: 'WF', country: 'Wallis and Futuna'},
  {alpha2: 'EH', country: 'Western Sahara'},
  {alpha2: 'YE', country: 'Yemen'},
  {alpha2: 'ZM', country: 'Zambia'},
  {alpha2: 'ZW', country: 'Zimbabwe'}
]
